.loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid gray;
    width: 42px;
    height: 42px;
    -webkit-animation: spin 0.7s linear infinite;
    animation: spin 0.7s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
